<!--index:通知 2024/07/03 22:51:52 YuAng-->

<template>
  <div class="notice-index">
    <ul class="notice-list" v-infinite-scroll="getData" :infinite-scroll-immediate=false
      :infinite-scroll-disabled="!loadMore" :infinite-scroll-distance=30>
      <li class="n-item" v-for="(item, index) in list" :key="'notice-' + index" @click="jump(item)">
        <div class="noticeList">
          <div class="container">
            <div class="textNotice">
              <div class="text">
                <p>{{ getNoticeDesc(item) }}</p>
              </div>
              <div class="get">
                <div class="time">{{ new Date(item.gmtCreate).getTime() | formatDate }}</div>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import { noticeApi } from '@/utils/api'
export default {
  name: 'index',
  props: {
    item: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      currentPage: 1,
      loadMore: true,
      isloading: false,
      list: []
    }
  },
  created () {
    // 请求接口
  },
  mounted () {
    this.getData()
  },
  methods: {
    getNoticeDesc: function (item) {
      if (item.chatType === 'PRIVATE_MESSAGE') {
        return item.userName + this.$t('messageToYou')
      }
      if (item.chatType === 'ARTICLE_COMMENT') {
        return item.userName + this.$t('commentYouArticle')
      }
      if (item.chatType === 'COMMENT_REPLY') {
        return item.userName + this.$t('replyYourComment')
      }
      if (item.interactionType === 'ATTENTION') {
        return item.userName + this.$t('attentionYou')
      }
      if (item.interactionType === 'LIKE' && item.bizType === 'INTERACTION') {
        return item.userName + this.$t('likeYourArticle')
      }
      if (item.interactionType === 'LIKE' && item.bizType === 'CHAT') {
        return item.userName + this.$t('likeYourComment')
      }
      if (item.interactionType === 'STAR') {
        return item.userName + this.$t('starYourArticle')
      }
      if (item.interactionType === 'TRAMPLE') {
        return item.userName + this.$t('trampleYourArticle')
      }
      return ''
    },
    jump: function (item) {
      if (item.articleId) {
        this.$router.push('/post/' + item.articleId)
      }
    },
    getData: function () {
      if (this.isloading) {
        return
      }
      this.isloading = true
      noticeApi.getNoticeList(this.currentPage).then(resp => {
        this.isloading = false
        if (resp.httpStatus === 200 && resp.data) {
          console.log('notice', resp)
          this.loadMore = this.currentPage * 10 < resp.total
          this.currentPage++
          this.list = this.list.concat(resp.data)
        }
      }).catch(err => {
        console.log('err', err)
        this.isloading = false
      })
    }
  }
}
</script>

<style scoped>
.container {
  width: 778px;
}

.textNotice {
  width: 778px;
  height: 100%;
  padding: 20px 28px 19px 20px;
  background-color: rgb(255, 255, 255);
  border-radius: 14px;
  margin-bottom: 20px;
  overflow: hidden;
  position: relative;
}

.textNotice .text {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  line-height: 1.5rem;
  color: rgb(82, 82, 82);
  width: 90%;
}

.textNotice .get {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.textNotice .get .time {
  position: absolute;
  top: 24px;
  right: 24px;
  color: #afafaf;
}

.notice-list {
  min-height: 700px;
  overflow-y: auto;
}

.notice-list {
  cursor: pointer;
}
</style>
